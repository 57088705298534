.services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  .container{
    margin-bottom: 50px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
  }
  
  .center-image {
    width: 500px;
    height: 500px;
    transform: rotate(45deg);
  }
  
  .card {
    max-width: 300px;
    height: 215px;
    background-color: #fff;
    padding: 20px 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in-out;
    border-radius: 15px;
  }
  
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
      align-items: center;
    }

    .center-image {
      display: none;
  }
  }
  