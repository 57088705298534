.contact-info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

}

.contact-item {
  flex: 1;
  text-align: center;
}

.contact-item h4 {
  font-size: 18px;
  margin-bottom: 5px;
  color:black
}

.contact-item p {
  font-size: 16px;
  margin-bottom:20px ;
  color: black;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .contact-item {
    flex: none;
    width: 100%;
    margin-bottom: 20px;
  }
}
