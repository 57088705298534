* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.header-container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}

.header-logo {
    margin: 10px;
    flex-shrink: 0;
}

.header-video-container {
    background-color: #3b82c6;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-grow: 1;
    width: calc(100% - 270px);
}

.header-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-title {
    color: white;
    font-size: 75px;
    z-index: 1;
    text-align: center;
}
.header-logo img {
    height: 200px;
    width: 200px;
}

/* Media Queries */
@media (max-width: 768px) {
    .header-title {
        font-size: 50px;
    }

    .header-video-container {
        height: 200px;
        width: 100%;
    }

    .header-logo img {
        height: 200px;
        width: 150px;
    }
}

@media (max-width: 480px) {
    .header-title {
        font-size: 35px;
    }

    .header-video-container {
        height: 150px;
        width: 100%;
    }

    .header-logo img {
        height: 150px;
        width: 150px;
    }
}
