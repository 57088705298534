.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: url('../assets/waves.svg'); 
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    color: #fff;
    text-align: center;
    height: 250px;
  }
  
  .footer-content {
    padding: 20px;
    width: 100%;
  }
  .footer-content p a{
    text-decoration: none; /* Enlever le soulignage */
    color: white;

  }
  